<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY LIQUIDATION - {{title}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="12"
          cols="12"
        >
          <v-row dense>
            <v-col
              md="3"
              cols="12"
            >
              <v-data-table dense
                            :headers="headers2"
                            :items="available_particulars"
                            :height="320"
                            :disable-pagination="true"
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr @click="activerow_particulars(item)"
                      :class="{'info black--text': item.id===selectedParticularsID}"
                  >
                    <td>
                      {{ item.particulars}}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              md="9"
              cols="12"
              dense
              v-if="selectedParticularsID!=-1"
            >
              <v-form ref="form" class="multi-col-validation">
                <v-row dense>
                  <v-col md="12"
                         cols="12" v-if="can_select_category">
                    <v-select
                      class="mx-2"
                      v-model="category_id"
                      dense
                      label="Category Of"
                      :items="category_items"
                      item-text="category"
                      item-value="id"
                      outlined
                      :rules="rules.combobox_rule"
                      @change="selected_category"
                    ></v-select>
                  </v-col>
                  <v-col md="12"
                         cols="12">
                    <v-select
                      class="mx-2"
                      v-model="branch_id"
                      dense
                      label="Branch"
                      :items="branch_items"
                      item-value="id"
                      item-text="branch_code"
                      outlined
                      :rules="rules.combobox_rule"
                      v-if="branch===null"
                    ></v-select>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="branch"
                      dense
                      label="Branch"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="12"
                         cols="12" v-if="can_select_particulars_of">
                    <v-select
                      class="mx-2"
                      v-model="particulars_of"
                      dense
                      label="Particulars Of"
                      :items="particulars_of_items"
                      item-text="particulars"
                      item-value="id"
                      outlined
                      :rules="rules.combobox_rule"
                      @change="selected_particulars"
                    ></v-select>
                  </v-col>
                  <v-col md="12"
                         cols="12">
                    <customize-components
                      v-if="has_description"
                      :key="this.key"
                      :description="this.description"
                      :category_id="this.employee_category_id"
                      :particulars_id="this.particulars_of"
                      :particulars_items="this.particulars_of_items"
                      :branch_id="this.branch_id"
                      :particulars_description_items="this.particulars_description_items"
                      v-on:on_is_ca="on_is_ca"
                      v-on:on_close_description_dialog_have_amount="on_close_description_dialog_have_amount"
                      v-on:on_maf_no="on_maf_no"
                      v-on:on_close_description_dialog="on_close_description_dialog"
                    ></customize-components>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="description"
                      dense
                      label="Description"
                      outlined
                      :rules="rules.default_max_500_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12"
                         cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="payment_for"
                      dense
                      label="Payment For"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="12"
                         cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="credit_to"
                      dense
                      label="Credit To"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6"
                         cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="date"
                      dense
                      label="Date"
                      outlined
                      type="date"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6"
                         cols="12">
                    <v-text-field
                      class="mx-2"
                      v-model="amount"
                      dense
                      label="Amount"
                      outlined
                      min="0"
                      type="number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-checkbox
                    v-model="is_no_or"
                    label="Is No OR #"
                    dense
                    outlined
                  ></v-checkbox>
                  <v-col md="10"
                         cols="12">
                    <v-text-field
                      v-if="!is_no_or"
                      class="mx-2"
                      v-model="or_no"
                      dense
                      label="OR #"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      class="mx-2"
                      v-model="specify"
                      dense
                      label="Specify"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-card-text class="d-flex">
                      <v-row>
                        <v-col md="12" cols="12" class="mt-4">
                          <vue-croppie width="100%" cover ref="croppieRef" :enableOrientation="true"
                                       :mouseWheelZoom="false"
                                       :boundary="{ width: 950, height: 450}"
                                       :viewport="{ width:925, height:425, 'type':'square' }">
                          </vue-croppie>
                        </v-col>
                        <v-col md="12" cols="12">
                          <!-- upload photo -->
                          <div>
                            <v-btn color="primary" class="me-3 mt-5"
                                   @click="$refs.refInputEl.click()">
                              <v-icon class="d-sm-none">
                                {{ icons.mdiCloudUploadOutline }}
                              </v-icon>
                              <span class="d-none d-sm-block">Select Receipt Photo</span>
                            </v-btn>

                            <input
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.jpg"
                              :hidden="true"
                              v-on:click="$refs.refInputEl.value = ''"
                              v-on:change="croppie"
                            />

                            <v-btn color="error" outlined class="mt-5" @click="resett()"> Reset
                            </v-btn>
                            <p class="text-sm mt-5">Allowed JPG or JPEG Only</p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" v-show="alert">
                    <v-alert
                      color="warning"
                      text
                      class="mb-0"

                    >
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{alert_message}}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn
                      class="w-full"
                      color="primary"
                      @click="save_liquidation"
                      v-if="!saving_data"
                    >
                      Save changes
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn
                      class="w-full"
                      color="success"
                      @click="returnFundDialog=true"
                      v-if="!returnFundDialog"
                    >
                      Return Fund
                    </v-btn>
                    <v-progress-circular
                      :size=50
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          md="12"
          cols="12">
          <!--          <h2 v-if="!is_deleting_request && liquidated_items.length > 0">-->
          <!--            Print |-->
          <!--            <v-icon-->
          <!--              color="success"-->
          <!--              @click="print_data()"-->
          <!--            >-->
          <!--              {{ icons.mdiPrinter }}-->
          <!--            </v-icon>-->
          <!--            <v-progress-circular-->
          <!--              color="info"-->
          <!--              indeterminate-->
          <!--              v-if="is_deleting_request"-->
          <!--            ></v-progress-circular>-->
          <!--          </h2>-->
          <v-data-table dense
                        :headers="headers3"
                        :items="liquidated_items"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.cash_voucher.voucher_no
                  }}
                </td>
                <td>
                  {{ item.category.category
                  }}
                </td>
                <td>
                  {{ item.branch.branch_code
                  }}
                </td>
                <td>
                  {{ item.credit_to
                  }}
                </td>
                <td>
                  {{ item.particulars.particulars
                  }}
                </td>
                <td>
                  {{ item.description
                  }}
                </td>
                <td>
                  {{ formatPrice(item.amount)
                  }}
                </td>
                <td>
                  {{ item.or_no
                  }}
                </td>
                <td>
                  {{ item.specify
                  }}
                </td>
                <td class="text-center" v-if="employee_id===item.created_id">
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="error"
                    @click="delete_data(item.id)"
                  >
                    {{icons.mdiDelete }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="returnFundDialog" persistent max-width="30%">
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-row dense>
              <v-col md="12"
                     cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="date_deposited"
                  dense
                  label="Date Deposited"
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="time_deposited"
                  dense
                  label="Time Deposited"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-select
                  class="mx-2"
                  v-model="bank_id"
                  dense
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  outlined
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="bank_account_no"
                  dense
                  label="Account #"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="6"
                     cols="12">
                <v-text-field
                  class="mx-2"
                  v-model="amount"
                  dense
                  label="Amount"
                  outlined
                  min="0"
                  type="number"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12"
                     cols="12">
                <v-select
                  class="mx-2"
                  v-model="deposit_status"
                  dense
                  label="Status"
                  :items="['Excess','Full']"
                  outlined
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col cols="12" v-show="alert_da">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message_da}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="save_return_fund"> Save Changes</v-btn>
      <v-btn color="error" @click="returnFundDialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch,
    mdiDelete,
    mdiPrinter,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import customizeComponents
    from "@/views/file_interface/create/request/request_components/CustomizeRequest";

  const initialState = () => {
    return {
      avatarImg: require('@/assets/images/misc/upload_cheque.jpg'),

      croppieImage: '',
      cropped: false,

      is_view: false,
      saving_data: false,
      alert: false,
      alert_message: '',
      saving_data_ret: false,
      alert_da: false,
      alert_message_da: '',

      is_deleting: false,
      is_deleting_request: false,

      returnFundDialog: false,
      is_no_or: false,
      specify: '',
      or_no: '',
      selectedVoucherID: -1,
      selectedParticulars: -1,
      selectedParticularsID: -1,
      month_of_id: '',
      month_items: [],
      liquidated_items: [],
      unliquidated_vouchers: [],
      available_particulars: [],
      particulars_of_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'employee.last_name', sortable: false},
      ],
      headers2: [
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
      ],
      headers3: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Credited To', value: 'date_of_deposit', sortable: false},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Description', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'date_of_deposit', sortable: false},
        {text: 'OR #', value: 'date_of_deposit', sortable: false},
        {text: 'Details', value: 'date_of_deposit', sortable: false},
        {text: 'Action', value: 'date_of_deposit', sortable: false},
      ],
      data: [],
      amount: '0',
      date: '',
      branch: '',
      credit_to: '',
      payment_for: '',
      particulars_of: '',
      description: '',
      can_select_particulars_of: true,
      can_select_category: false,
      request_data_id: 0,
      cash_voucher_id: 0,
      branch_id: 0,
      branch_items: [],
      key: 0,

      bank_account_no: '',
      bank_id: '',
      bank_items: [],
      time_deposited: '',
      date_deposited: '',
      deposit_status: '',
      has_description: false,
      particulars_selected: '',
      particulars_description_items: [],
    }
  }
  export default {
    props: {
      title: String,
    },
    components: {
      snackBarDialog,
      customizeComponents,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiDelete,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'position', 'employee_category_id', 'employee_branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
        'employee_branch_code',
        'name',
        'name_credit'
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('category', ['list_of_all_active_categories']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('monthly_liquidation', ['initialization_liquidation', 'list_of_voucher_for_ca_liquidation', 'list_of_category_for_ca_liquidation', 'list_of_particulars_for_department_liquidation', 'list_of_particulars_for_sales_drive_liquidation', 'register_regular_liquidation', 'list_of_encoded_department_liquidation', 'delete_regular_liquidation', 'list_of_encoded_sales_drive_liquidation']),
      ...mapActions('bank_depository', ['list_of_bank_not_confidential_for_return_only',]),
      ...mapActions('request_particulars', ['request_particulars_data',]),
      croppie(e) {
        this.cropped = false
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = true
          this.$refs.croppieRef.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
      },
      resett() {
        this.$refs.croppieRef.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl.value = ''
        this.cropped = false
        this.croppieImage = ''
        this.alert = false
      },
      delete_data(value) {
        this.is_deleting = true
        this.delete_regular_liquidation({
          id: value
        })
          .then(() => {
            this.initialize_data()
            this.is_deleting = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      async initialize_data() {
        this.can_select_category = this.employee_category_id === 2
        if (this.can_select_category) {
          this.list_of_all_active_categories()
            .then(response => {
              this.category_items = response.data
            })
        }
        await this.list_of_bank_not_confidential_for_return_only()
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        if (this.title === 'DAMAYAN SALES DRIVE') {
          await this.list_of_particulars_for_sales_drive_liquidation({
            name: this.name_credit,
            created_id: this.employee_id,
            category_id: this.employee_category_id,
            branch_id: this.employee_branch_id,
          })
            .then(response => {
              this.request_data_id = 0
              this.cash_voucher_id = 0
              this.selectedParticularsID = -1
              if (response.status === 200) {
                this.available_particulars = response.data
              }
            })
            .catch(error => {
              console.log(error)
            })

          await this.list_of_encoded_sales_drive_liquidation({
            department: this.position,
            created_id: this.employee_id,
            category_id: this.employee_category_id,
            branch_id: this.employee_branch_id,
          })
            .then(response => {
              if (response.status === 200) {
                this.liquidated_items = response.data
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          await this.list_of_particulars_for_department_liquidation({
            department: this.position,
            created_id: this.employee_id,
            category_id: this.employee_category_id,
            branch_id: this.employee_branch_id,
          })
            .then(response => {
              this.request_data_id = 0
              this.cash_voucher_id = 0
              this.selectedParticularsID = -1
              if (response.status === 200) {
                this.available_particulars = response.data
              }
            })
            .catch(error => {
              console.log(error)
            })
          await this.list_of_encoded_department_liquidation({
            department: this.position,
            created_id: this.employee_id,
            category_id: this.employee_category_id,
            branch_id: this.employee_branch_id,
          })
            .then(response => {
              if (response.status === 200) {
                this.liquidated_items = response.data
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
        if (this.employee_category_id != 2) {
          this.branch_id = this.employee_branch_id
          this.branch = this.employee_branch_code
        }


      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_close_description_dialog(value) {
        this.description = value
      },
      on_maf_no(value) {
        this.maf_no = value
      },
      on_close_description_dialog_have_amount(value) {
        this.disable_amount = true
        this.amount = value
      },
      on_is_ca(value) {
        this.is_ca = value
      },
      selected_category(value) {
        this.request_particulars_data({
          category_id: value,
          is_liquidation: 1,
        })
          .then(response => {
            this.particulars_of_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
        this.branch = null
        this.branch_data({
          category_id: value,
          is_region: 0,
          is_territory: 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow_particulars(value) {
        this.category_id = this.employee_category_id
        this.selectedParticularsID = value.id;
        this.branch = ''
        this.data = value.data[0]
        this.cash_voucher_id = this.data.cv_id
        this.particulars_selected = value.particulars.split(". ")[1]
        this.request_data_id = this.data.id
        this.description = this.data.description
        this.payment_for = this.data.payment_for
        this.credit_to = this.data.credit_to
        this.branch_id = this.data.branch_id
        this.month_of_id = this.data.month_of_id
        this.branch = null
        if (this.data.branch != null) {
          this.branch = this.data.branch.branch_code
          if (this.employee_category_id != 2) {
            this.branch_id = this.employee_branch_id
            this.branch = this.employee_branch_code
          }
        }
        this.amount = this.data.amount + ''
        this.can_select_particulars_of = true
        this.particulars_of_items = []

        if (this.employee_category_id === 4) {
          if (this.title === 'DAMAYAN SALES DRIVE') {
            this.can_select_particulars_of = false
            this.particulars_of_items = []
          } else {
            this.particulars_of_items = [
              'Office Supply',
              'Internet',
              'Freight',
            ]
          }
          this.description = ''
        } else if (this.employee_category_id === 3) {
          this.particulars_of_items = [
            'Embalmer',
            'Driver',
            'Helper',
            'Memorial Expense',
            'Rental of Funeral Car',
            'Callers',
            'Kaagapay Grocery',
            'Fare/Bank Charges',
            'Gas-Up',
            'Water Bill',
            'Electic Bill',
            'Purchase Casket',
            'Chapel Supply/Expense',
          ]
        } else {
          this.request_particulars_data({
            category_id: this.employee_category_id,
            is_liquidation: 1,
          })
            .then(response => {
              this.particulars_of_items = response.data[0].active
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_particulars(value) {
        this.has_description = false
        this.description = ''
        this.key++
        if (this.particulars_of_items.length > 0) {
          var index = this.particulars_of_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.particulars_selected = this.particulars_of_items[index].particulars
            this.has_description = this.particulars_of_items[index].has_description === 1
            this.particulars_description_items = this.particulars_of_items[index].data
            if (this.has_description) {
              this.description = 'Please Click Me!!!'
            }
          }
        }
      },
      async save_liquidation() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          let options = {
            type: 'base64',
            format: 'jpeg'
          };
          await this.$refs.croppieRef.result(options, output => {
            this.croppieImage = output;
          });
          if (this.selectedParticularsID === -1) {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert = true
            this.alert_message = 'Please Select Particular First'
            this.saving_data = false
          } else if (!this.cropped) {
            this.alert = true
            this.alert_message = 'Please Upload Receipt Photo'
            this.saving_data = false
          } else if (parseFloat(this.amount) > parseFloat(this.data.amount)) {
            this.alert = true
            this.alert_message = 'The amount is exceed of the balance!'
            this.saving_data = false
          } else {
            const data = new FormData()
            data.append('cash_voucher_id', this.cash_voucher_id);
            data.append('category_id', this.employee_category_id === 2 ? this.category_id : this.employee_category_id);
            data.append('month_of_id', this.month_of_id);
            data.append('date', this.date);
            data.append('credit_to', this.credit_to);
            data.append('payment_for', this.payment_for);
            var final_particlars = this.particulars_selected
            switch (this.particulars_selected) {
              case "Branch Expense":
              case "Regional Expense":
              case "Benefits":
                final_particlars = this.description.split(' # ')[0]
                break;
              case "HO Budget":
              case "Revolving Fund":
              case "Chapel Fund":
              case "Poultry Fund":
                final_particlars = this.particulars_of
                break;
            }
            data.append('particulars', final_particlars);
            data.append('amount', this.amount);
            data.append('description', this.description);
            data.append('branch_id', this.branch_id);
            data.append('request_data_id', this.request_data_id);
            data.append('is_return_fund', 0);
            data.append('bank_id', 0);
            data.append('date_deposited', null);
            data.append('time_deposited', null);
            data.append('deposit_status', null);
            data.append('is_no_or', this.is_no_or);
            data.append('or_no', this.or_no);
            data.append('specify', this.specify);
            data.append('employee_id', this.employee_id);
            data.append('receipt_location', this.croppieImage);
            await this.register_regular_liquidation(data)
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: 'SUCCESSFULLY LIQUIDATE',
                })
                this.saving_data = false
                Object.assign(this.$data, initialState())
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving_data = false
              })

            await this.initialize_data()
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.bank_account_no = this.bank_items[index].account_no
        }
      },
      async save_return_fund() {
        this.saving_data_ret = true
        if (this.$refs.form2.validate()) {
          if (this.selectedParticularsID === -1) {
            this.alert_da = true
            this.alert_message_da = 'Please Select Particular First'
            this.saving_data = false
          } else if (this.description === 'Please Click Me!!!') {
            this.alert = true
            this.alert_message_da = 'Please Select Particular First'
            this.saving_data_da = false
          } else if (parseFloat(this.amount) > parseFloat(this.data.amount)) {
            this.alert = true
            this.alert_message = 'The amount is exceed of the balance!'
            this.saving_data = false
          } else {
            if (this.description === '') {
              this.description = this.data.description
            }
            const data = new FormData()
            data.append('cash_voucher_id', this.cash_voucher_id);
            data.append('category_id', this.employee_category_id);
            data.append('month_of_id', this.month_of_id);
            data.append('date', moment().format('YYYY-MM-DD'));
            data.append('credit_to', this.credit_to);
            data.append('payment_for', this.payment_for);
            var final_particlars = this.particulars_selected
            switch (this.particulars_selected) {
              case "Branch Expense":
              case "Regional Expense":
              case "Benefits":
                final_particlars = this.description.split(' # ')[0]
                break;
              case "HO Budget":
              case "Revolving Fund":
              case "Chapel Fund":
              case "Poultry Fund":
                final_particlars = this.particulars_selected
                break;
            }
            data.append('particulars', final_particlars);
            data.append('amount', this.amount);
            data.append('description', this.description);
            data.append('branch_id', this.branch_id);
            data.append('request_data_id', this.request_data_id);
            data.append('is_return_fund', 1);
            data.append('bank_id', this.bank_id);
            data.append('date_deposited', this.date_deposited);
            data.append('time_deposited', this.time_deposited);
            data.append('deposit_status', this.deposit_status);
            data.append('is_no_or', this.is_no_or);
            data.append('or_no', this.or_no);
            data.append('specify', this.specify);
            data.append('employee_id', this.employee_id);
            this.register_regular_liquidation(data)
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: 'SUCCESSFULLY LIQUIDATE',
                })
                this.returnFundDialog = false
                Object.assign(this.$data, initialState())
              })
              .catch(error => {
                this.alert = true
                this.alert_message_da = error
                this.saving_data_da = false
              })
            await this.initialize_data()
            this.key++
          }
        } else {
          this.alert_da = true
          this.alert_message_da = 'Please Fill up the field/s'
          this.saving_data_ret = false
        }
      },
      async print_data() {
        this.is_deleting_request = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [35, 80, 80, 150, 40, 40, 60]
        payments_array.push([
          {text: 'Voucher #', alignment: 'left', style: 'main_info'},
          {text: 'Credited To', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
          {text: 'OR #', alignment: 'left', style: 'main_info'},
          {text: 'Details', alignment: 'left', style: 'main_info'},

        ])

        this.liquidated_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.cash_voucher.voucher_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.credit_to,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.particulars.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.amount,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.or_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.specify,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'POSITION: ',
                  {
                    text: this.position,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'DEPARTMENT LIQUIDATION',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting_request = false
      },
    },
  }
</script>
